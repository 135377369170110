<template>
  <div class="head">
    <div class="yj-top">
      <div class="yj-topA">
        <img :src="yjlogo" class="yj-img-logoA" alt="" @click="goUrl" />
        <img :src="rrjx" class="yj-img-logoB" alt="" />
      </div>
    </div>
    <div :class="`index-top ${this.$route.name === 'help' ? 'index-top1' : ''}`">
      <div>
        <a href="/"><img :src="heilogo" class="img-logo" alt=""/></a>
      </div>
      <!-- 帮助详情页中间内部显示 -->
      <div v-if="this.$route.name === 'help'">
        <div class="helpcenter">
          <span style="border-left: 2px solid #1890ff; padding-left: 22px; display: block; float: left">帮助中心</span>
          <div
            id="searchhelp"
            style="margin-left: 36px; float: left"
            @mouseover="searchResultOver"
            @mouseleave="searchResultLeave"
          >
            <el-input v-model="keyword" placeholder="搜索问题" class="keyword" style="height: 50px">
              <el-button slot="append" icon="el-icon-search" class="enterButton" @click="search" />
            </el-input>
            <div class="search-result" style="display: none">
              <span v-if="isResult" class="search-no-content">暂无相关结果</span>
              <span v-for="(item, key) in searchList" :key="key" class="search-content" @click="searchResult(item)">
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.$route.name !== 'help'" @click="backTop">
        <div>
          <router-link
            :to="{ name: 'index' }"
            :class="`index-center ${this.$route.name === 'index' ? 'head-color' : ''}`"
            >首页</router-link
          >
          <div class="marginLeft80 index-center digitalresource" @click="yujiaoGZ">豫教高中</div>
          <div class="marginLeft80 index-center digitalresource" @click="yujiao">豫教听读</div>
          <router-link
            :to="{ name: 'jiaocai' }"
            :class="`marginLeft80 index-center digitalresource ${this.$route.name === 'jiaocai' ? 'head-color' : ''}`"
            >数字教材</router-link
          >

          <!-- <router-link :to="{ name: 'jiaocan' }"
            :class="
              `marginLeft80 index-center digitalresource ${this.$route.name === 'jiaocan' ? 'head-color' : ''}`
            ">数字教参</router-link> -->
          <!-- <router-link :to="{name: 'apply'}"
            :class="`marginLeft80 index-center digitalresource ${this.$route.name === 'apply' ? 'head-color' : ''}`">智慧应用</router-link> -->
          <router-link
            :to="{ name: 'assessment' }"
            :class="`marginLeft80 index-center big-data ${this.$route.name === 'assessment' ? 'head-color' : ''}`"
            >大数据测评</router-link
          >
          <router-link
            :to="{ name: 'ai' }"
            :class="`marginLeft80 index-center digitalresource ${this.$route.name === 'ai' ? 'head-color' : ''}`"
            >人工智能</router-link
          >
          <router-link
            :to="{ name: 'resource' }"
            :class="`marginLeft80 index-center digitalresource ${this.$route.name === 'resource' ? 'head-color' : ''}`"
            >数字资源</router-link
          >
          <!-- <router-link :to="{name: 'notice'}"
            :class="`marginLeft80 index-center digitalresource ${this.$route.name === 'notice' ? 'head-color' : ''}`">活动通知</router-link> -->
          <router-link
            :to="{ name: 'helpme' }"
            :class="
              `marginLeft80 index-center ${
                this.$route.name && this.$route.name.indexOf('help') >= 0 ? 'head-color' : ''
              }`
            "
            >帮助</router-link
          >
        </div>
      </div>
      <!-- 这里是根据是否登录判断(已登录则显示进入空间) -->
      <div v-if="!cookie" class="login-register">
        <el-button @click="register">注册</el-button>
        <el-button type="primary" @click="login">登录</el-button>
      </div>
      <div v-else class="login-register">
        <el-button @click="goMyApp">我的空间</el-button>
        <el-button type="primary" @click="logout">退出</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import heilogo from '../../assets/img/index/heilogo.png';
import rrjx from '../../assets/img/index/rrjx.png';
import yjlogo from '../../assets/img/index/pclogo.svg';
import { articleyGet } from '../api.js';

export default {
  props: {
    searchVisible: Number,
    loginType: String,
  },
  data() {
    return {
      rrjx,
      yjlogo,
      heilogo,
      keyword: '',
      // 搜索内容
      searchList: [],
      // 是否有搜索结果
      isResult: false,
      // 点击搜索为true
      isSearch: false,
      // 是否滚动
      isScroll: false,
    };
  },
  watch: {
    searchVisible() {
      this.clickBody();
    },
  },
  mounted() {
    if (this.$route.name === 'index') {
      window.addEventListener('scroll', this.handleScroll);
    }
    // 监听到stroage变化就刷新页面,及时更新登录状态
    if (navigator.userAgent.indexOf('Chrome') > 0) {
      window.addEventListener('storage', () => {
        window.location.reload();
      });
      window.addEventListener('keydown', event => {
        if (event.keyCode === 13) {
          this.search();
          return false;
        }
      });
    }
  },
  created() {},
  methods: {
    yujiao() {
      window.open('http://hnitd.com', '_blank');
    },
    yujiaoGZ() {
      if (process.env.NODE_ENV === 'production') {
        window.open('https://hnigz.com', '_blank');
      } else {
        window.open('http://192.168.1.98/gz-site', '_blank');
      }
    },
    goMyApp() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = window.config.ssoUrlOnline;
      } else {
        window.location.href = window.config.applicationUrl;
      }
    },
    logout() {
      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${
        window.location.hostname.indexOf('szjcyyy.com') > -1 ? 'szjcyyy.com' : ''
      };path = /`;
      window.location.href = '';
    },
    /* 搜索问题 */
    search() {
      // 搜索结果
      const title = document.querySelector('.search-result');
      title.style.display = 'block';
      if (!this.keyword) {
        this.isResult = true;
        return false;
      }
      articleyGet({ keyword: _.trim(this.keyword, ' ') }).then(({ data, err }) => {
        if (!err) {
          const { data: list } = data;
          this.searchList = list;
          this.isResult = _.isEmpty(this.searchList);
        }
      });
    },
    /* 搜索结果 */
    searchResult(val) {
      const title = document.querySelector('.search-result');
      title.style.display = 'none';
      this.$emit('search', val);
    },
    /* 鼠标移入搜索内容 */
    searchResultOver() {
      this.isSearch = false;
    },
    /* 鼠标离开搜索内容 */
    searchResultLeave() {
      this.isSearch = true;
    },
    /* 取消搜索结果 */
    clickBody() {
      // 搜索结果
      if (this.isSearch) {
        const title = document.querySelector('.search-result');
        title.style.display = 'none';
      }
    },
    /* 注册 */
    register() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = `${window.config.ssoUrlOnline}?type=register`;
      } else {
        window.location.href = window.config.registerUrl;
      }
      /* this.loginType === '1'
        ? window.publicRegister({ appid: 'ae8783dbf00944c886e24d642a9baf6e' })
        : (window.location.href = window.config.registerUrl); */
    },
    /* 登录 */
    login() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = window.config.ssoUrlOnline;
      } else {
        window.location.href = window.config.loginUrl;
      }
      /* this.loginType === '1'
        ? window.publicLogin({ appid: 'ae8783dbf00944c886e24d642a9baf6e' })
        : (window.location.href = window.config.loginUrl); */
    },
    backTop() {
      const back = setInterval(() => {
        if (document.body.scrollTop || document.documentElement.scrollTop) {
          document.body.scrollTop -= 100;
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(back);
        }
      });
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.$route.name === 'index') {
        if (scrollTop > 0) {
          this.isScroll = true;
        } else {
          this.isScroll = false;
        }
      }
    },
    goUrl() {
      window.open('https://www.hasmartedu.com/');
    },
  },
};
</script>

<style>
#searchhelp .el-input--small .el-input__inner {
  width: 466px;
  height: 50px !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(229, 229, 229, 1);
}
.el-input-group__append {
  width: 50px;
  height: 50px;
  padding: 0px;
  top: -1px;
  background: rgba(24, 144, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.el-icon-search {
  font-size: 20px;
  color: #fff;
}
.el-input-group__append .el-button,
.el-input-group__append .el-select,
.el-input-group__prepend .el-button,
.el-input-group__prepend .el-select {
  margin: 0px;
}
.el-collapse-item__header {
  padding-left: 34px;
  font-size: 16px;
}
</style>

<style lang="less" scoped>
.head {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 143px;
  z-index: 1122;
  background: #fff;
  // box-shadow: 0px 2px 8px 0px rgba(220, 220, 220, 0.5);
  .yj-top {
    height: 64px;
    background: linear-gradient(270deg, #256af0 0%, #2064eb 100%);
    .yj-topA {
      width: 1200px;
      margin: auto;
      text-align: center;
      .yj-img-logoA {
        height: 40px;
        margin-top: 12px;
        float: left;
        cursor: pointer;
      }
      .yj-img-logoB {
        float: right;
        height: 24px;
        margin-top: 20px;
      }
    }
  }
}
.isindex {
  background: rgba(255, 255, 255, 0);
}
.index-top {
  display: flex;
  -webkit-justify-content: space-between;
  width: 1200px;
  margin: auto;
}
.index-top1 {
  // width: 100%;
}
.img-logo {
  width: 200px;
  height: 46px;
  margin-top: 17px;
}
.index-center {
  width: 60px;
  text-align: center;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  padding-top: 29px;
  padding-bottom: 25px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  cursor: pointer;
}
.index-head-center {
  width: 60px;
  text-align: center;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  padding-top: 29px;
  padding-bottom: 25px;
  font-size: 16px;
  color: #fff;
  display: inline-block;
}
.big-data {
  width: 108px;
}
.digitalresource {
  width: 92px;
}
.login-register {
  margin-top: 28px;
  min-width: 140px;
}
.marginLeft80 {
  margin-left: 20px;
}
.head-color {
  display: inline-block;
  color: #1890ff;
  border-bottom: 4px solid #1890ff;
}
.index-bottom-color {
  border-bottom: 4px solid #1890ff;
}
.helpcenter {
  // position: absolute;
  // left: 252px;
  // top: 15px;
  line-height: 50px;
  font-size: 22px;
  color: #000;
  margin-top: 15px;
}
/* 搜索结果 */
.search-result {
  width: 519px;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 8px;
}
.search-content {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 503px;
  padding-left: 16px;
  color: #595959;
  font-size: 14px;
}
.search-content:hover {
  color: #1890ff;
  background: #fafafa;
  cursor: pointer;
}
.search-no-content {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 503px;
  padding-left: 16px;
  color: #595959;
  font-size: 14px;
}
/*滚动条样式*/
.search-result::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.search-result::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
