var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "head" }, [
    _c("div", { staticClass: "yj-top" }, [
      _c("div", { staticClass: "yj-topA" }, [
        _c("img", {
          staticClass: "yj-img-logoA",
          attrs: { src: _vm.yjlogo, alt: "" },
          on: { click: _vm.goUrl }
        }),
        _c("img", {
          staticClass: "yj-img-logoB",
          attrs: { src: _vm.rrjx, alt: "" }
        })
      ])
    ]),
    _c(
      "div",
      {
        class: "index-top " + (this.$route.name === "help" ? "index-top1" : "")
      },
      [
        _c("div", [
          _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              staticClass: "img-logo",
              attrs: { src: _vm.heilogo, alt: "" }
            })
          ])
        ]),
        this.$route.name === "help"
          ? _c("div", [
              _c("div", { staticClass: "helpcenter" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "border-left": "2px solid #1890ff",
                      "padding-left": "22px",
                      display: "block",
                      float: "left"
                    }
                  },
                  [_vm._v("帮助中心")]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "margin-left": "36px", float: "left" },
                    attrs: { id: "searchhelp" },
                    on: {
                      mouseover: _vm.searchResultOver,
                      mouseleave: _vm.searchResultLeave
                    }
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "keyword",
                        staticStyle: { height: "50px" },
                        attrs: { placeholder: "搜索问题" },
                        model: {
                          value: _vm.keyword,
                          callback: function($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword"
                        }
                      },
                      [
                        _c("el-button", {
                          staticClass: "enterButton",
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.search },
                          slot: "append"
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-result",
                        staticStyle: { display: "none" }
                      },
                      [
                        _vm.isResult
                          ? _c("span", { staticClass: "search-no-content" }, [
                              _vm._v("暂无相关结果")
                            ])
                          : _vm._e(),
                        _vm._l(_vm.searchList, function(item, key) {
                          return _c(
                            "span",
                            {
                              key: key,
                              staticClass: "search-content",
                              on: {
                                click: function($event) {
                                  return _vm.searchResult(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        this.$route.name !== "help"
          ? _c("div", { on: { click: _vm.backTop } }, [
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      class:
                        "index-center " +
                        (this.$route.name === "index" ? "head-color" : ""),
                      attrs: { to: { name: "index" } }
                    },
                    [_vm._v("首页")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "marginLeft80 index-center digitalresource",
                      on: { click: _vm.yujiaoGZ }
                    },
                    [_vm._v("豫教高中")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "marginLeft80 index-center digitalresource",
                      on: { click: _vm.yujiao }
                    },
                    [_vm._v("豫教听读")]
                  ),
                  _c(
                    "router-link",
                    {
                      class:
                        "marginLeft80 index-center digitalresource " +
                        (this.$route.name === "jiaocai" ? "head-color" : ""),
                      attrs: { to: { name: "jiaocai" } }
                    },
                    [_vm._v("数字教材")]
                  ),
                  _c(
                    "router-link",
                    {
                      class:
                        "marginLeft80 index-center big-data " +
                        (this.$route.name === "assessment" ? "head-color" : ""),
                      attrs: { to: { name: "assessment" } }
                    },
                    [_vm._v("大数据测评")]
                  ),
                  _c(
                    "router-link",
                    {
                      class:
                        "marginLeft80 index-center digitalresource " +
                        (this.$route.name === "ai" ? "head-color" : ""),
                      attrs: { to: { name: "ai" } }
                    },
                    [_vm._v("人工智能")]
                  ),
                  _c(
                    "router-link",
                    {
                      class:
                        "marginLeft80 index-center digitalresource " +
                        (this.$route.name === "resource" ? "head-color" : ""),
                      attrs: { to: { name: "resource" } }
                    },
                    [_vm._v("数字资源")]
                  ),
                  _c(
                    "router-link",
                    {
                      class:
                        "marginLeft80 index-center " +
                        (this.$route.name &&
                        this.$route.name.indexOf("help") >= 0
                          ? "head-color"
                          : ""),
                      attrs: { to: { name: "helpme" } }
                    },
                    [_vm._v("帮助")]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        !_vm.cookie
          ? _c(
              "div",
              { staticClass: "login-register" },
              [
                _c("el-button", { on: { click: _vm.register } }, [
                  _vm._v("注册")
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.login } },
                  [_vm._v("登录")]
                )
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "login-register" },
              [
                _c("el-button", { on: { click: _vm.goMyApp } }, [
                  _vm._v("我的空间")
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.logout } },
                  [_vm._v("退出")]
                )
              ],
              1
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }